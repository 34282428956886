import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { useUser } from '../../contexts/auth/User';
import { SimpleManufacturerResponse } from '../../api/petcloudapi/api';
import { useGlobalStateManager } from '../../contexts/globalstatemanager/GlobalStateManager';

export type ManufacturerDict = { [key: string]: SimpleManufacturerResponse };

type ManufacturerOptionsOptions = {
  noAllManufacturers?: boolean;
  presetManufacturerOptions?: DropdownOption[] | null;
  // this can be used if the presetManufacturerOptions are null on initialization to prevent unnecessary api calls
  waitForPresetManufacturerOptions?: boolean;
};

const useManufacturerOptions = (opts?: ManufacturerOptionsOptions) => {
  const { user } = useUser();
  const { globalState, dispatchGlobal } = useGlobalStateManager();
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();

  const [manufacturerOptions, setManufacturerOptions] = useState<
    DropdownOption[] | null | 'NO_PERMISSION'
  >(opts?.presetManufacturerOptions ?? null);

  const [manufacturerDict, setManufacturerDict] = useState<
    ManufacturerDict | null | 'NO_PERMISSION'
  >(null);

  useEffect(() => {
    console.log('GETTING MANUFACTURERS');
    console.log('options are preset: ', opts?.presetManufacturerOptions);
    console.log(
      'waitForPresetManufacturerOptions: ',
      opts?.waitForPresetManufacturerOptions
    );
    if (user?.isProductOwner) {
      if (
        !opts?.presetManufacturerOptions ||
        !opts?.waitForPresetManufacturerOptions
      ) {
        if (!globalState.manufacturerOptions && !globalState.manufacturerDict) {
          manufacturersApi
            .manufacturersGetSimpleManufacturers()
            .then((response) => {
              console.log(response);
              const options = response.data.map((manufacturer) => {
                return {
                  id: manufacturer.id,
                  name: manufacturer.companyName,
                };
              });

              const dict: ManufacturerDict = {};
              response.data.forEach((manufacturer) => {
                dict[manufacturer.id] = manufacturer;
              });
              setManufacturerDict(dict);
              setManufacturerOptions(options);
              dispatchGlobal({
                type: 'ADD_STATE',
                key: 'manufacturerOptions',
                value: options,
              });
              dispatchGlobal({
                type: 'ADD_STATE',
                key: 'manufacturerDict',
                value: dict,
              });
            })
            .catch((error) => {
              console.log(error);
              errorHandler.addError(error.response);
            });
        } else {
          setManufacturerDict(globalState.manufacturerDict);
          setManufacturerOptions(globalState.manufacturerOptions);
        }
      } else {
        setManufacturerDict({});
        setManufacturerOptions(opts.presetManufacturerOptions);
      }
    } else {
      setManufacturerOptions('NO_PERMISSION');
      setManufacturerDict('NO_PERMISSION');
    }
  }, []);

  return {
    manufacturerOptions,
    manufacturerDict,
  };
};

export default useManufacturerOptions;
