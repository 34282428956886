// This is used to have the app-wide ability to set,
// check and remove states that are shared between parts of the application
// to avoid redundant api calls.
// This was added on 07.01.2025 and still needs to be adopted by many components of the app

import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
  useEffect,
} from 'react';
import useInitStates from './useInitStates';

interface GlobalState {
  [key: string]: any;
}

export type Action =
  | { type: 'ADD_STATE'; key: string; value: any }
  | { type: 'REMOVE_STATE'; key: string };

const globalStateReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  switch (action.type) {
    case 'ADD_STATE':
      return { ...state, [action.key]: action.value };
    case 'REMOVE_STATE':
      const { [action.key]: _, ...newState } = state; // Omit the key being removed
      return newState;
    default:
      return state;
  }
};

interface GlobalContextValue {
  state: GlobalState;
  dispatch: Dispatch<Action>;
}

const GlobalStateContext = createContext<GlobalContextValue | undefined>(
  undefined
);

// Provider component
export const GlobalStateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, {});
  const contextValue = { state, dispatch };
  const { initStates } = useInitStates(dispatch);

  useEffect(() => {
    initStates();
  }, []);

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hooks to access the global state and dispatch
export const useGlobalStateManager = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return { globalState: context.state, dispatchGlobal: context.dispatch };
};
