import './orderstateoverview.css';
import {
  SimpleOrderResponse,
  UnfulfilledOrdersResponse,
} from '../../../api/petcloudapi/api';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { states } from '../../../features/list/List';
import chartEmpty from '../../../../assets/img/chart_empty.svg';
import { useTranslation } from 'react-i18next';
import { HintBox, SmallHint } from '../../../elements/hint/Hint';
import Hoverable from '../../../elements/hoverable/Hoverable';
import { useNavigate } from 'react-router-dom';
import { useGlobalStateManager } from '../../../contexts/globalstatemanager/GlobalStateManager';
import { ReactComponent as IconWarning } from '../../../../assets/icon/warning.svg';

type OrderGroup = [string, SimpleOrderResponse[]];

type OrderGroupDict = {
  currentOrderState: OrderGroup[];
  currentTransactionState: OrderGroup[];
  currentShippingState: OrderGroup[];
};

interface OrderStateOverviewProps {
  orders: SimpleOrderResponse[];
  unfulfilledOrders: UnfulfilledOrdersResponse[];
  height?: number;
}

const OrderStateOverview: React.FC<OrderStateOverviewProps> = ({
  orders,
  unfulfilledOrders,
  height,
}) => {
  const { t } = useTranslation();
  const { globalState } = useGlobalStateManager();
  const [orderGroups, setOrderGroups] = useState<OrderGroupDict | null>(null);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const link = useNavigate();

  useEffect(() => {
    getOrderGroups();
  }, [orders]);

  const groupOrders = (
    orders: SimpleOrderResponse[],
    key: keyof SimpleOrderResponse
  ) => {
    return Object.entries(_.groupBy(orders, (x) => x[key]));
  };

  const getOrderGroups = () => {
    setTotalOrderCount(orders.length);
    const groupedByOrderState = groupOrders(
      orders,
      'currentOrderStateTechnicalName'
    );
    const groupedByTransactionState = groupOrders(
      orders,
      'currentTransactionStateTechnicalName'
    );
    const groupedByShippingState = groupOrders(
      orders,
      'currentShippingStateTechnicalName'
    );
    setOrderGroups({
      currentOrderState: groupedByOrderState,
      currentTransactionState: groupedByTransactionState,
      currentShippingState: groupedByShippingState,
    });
  };

  const getBarPercentage = (group: OrderGroup) => {
    return `${((group[1].length / totalOrderCount) * 100).toFixed(2)}%`;
  };

  if (orderGroups) {
    if (orderGroups.currentOrderState.length > 0) {
      return (
        <div className={'orderStateOverview'} style={{ height: height }}>
          <div className={'orderStateOverview-spotlights'}>
            <div className={'orderStateOverview-spotlight'}>
              <div
                className={
                  'orderStateOverview-spotlight-value orderStateOverview-spotlight-value__hoverable'
                }
                onClick={() => link('/productValidation')}
              >
                <IconWarning
                  className={
                    'orderStateOverview-spotlight-icon orderStateOverview-spotlight-icon__warning'
                  }
                />
                {globalState.validationResults?.length}
                <SmallHint
                  paragraphs={[
                    t(
                      'components.orderStateOverview.validationResultCount_hint'
                    ),
                  ]}
                />
              </div>
              <div className={'orderStateOverview-spotlight-title'}>
                {t('components.orderStateOverview.validationResultCount')}
              </div>
            </div>
            <div className={'orderStateOverview-spotlight'}>
              <div
                className={
                  'orderStateOverview-spotlight-value orderStateOverview-spotlight-value__hoverable'
                }
                onClick={() => link('/orders/#unfulfilled')}
              >
                {unfulfilledOrders.length}
                <SmallHint
                  paragraphs={[
                    t('components.orderStateOverview.unfulfilledOrders_hint'),
                  ]}
                />
              </div>
              <div className={'orderStateOverview-spotlight-title'}>
                {t('components.orderStateOverview.unfulfilledOrders')}
              </div>
            </div>
          </div>
          <div className={'orderStateOverview-stacks'}>
            {Object.entries(orderGroups).map(([key, value]) => {
              return (
                <div className={'orderStateOverview-stackedBar'}>
                  <div className={'orderStateOverview-stackedBar-title'}>
                    {t(`components.orderStateOverview.${key}`)}
                  </div>
                  <div className={'orderStateOverview-stackedBars'}>
                    {value.map((group) => {
                      const percentage = getBarPercentage(group);
                      const state = group[0];
                      const stateColor = states[state];
                      const text = percentage + ' ' + t(`list.states.${state}`);
                      return (
                        <Hoverable
                          helperClass={'orderStateOverview-stackedBars-bar'}
                          style={{
                            width: percentage,
                            backgroundColor: stateColor,
                            color: stateColor,
                          }}
                          onHoverNode={
                            <HintBox paragraphs={[text]} isToolTip />
                          }
                        ></Hoverable>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <EmptyState
          img={chartEmpty}
          size={110}
          message={'Keine Daten für diesen Zeitraum'}
        />
      );
    }
  } else {
    return <LoadingContainer />;
  }
};

export default OrderStateOverview;
