import { Action } from './GlobalStateManager';
import { Dispatch, useEffect } from 'react';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../errorhandler/ErrorHandler';

// these are all api calls that are either required when starting the app
// or are most likely to be used throughout an app session,
// so they are getting proactively called here.

const useInitStates = (dispatchGlobal: Dispatch<Action>) => {
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();

  const initStates = () => {
    // required to show the notification count in the sidebar without the need
    // to first visit the product validation list
    productsApi
      .productsGetValidationResults(undefined, undefined)
      .then((response) => {
        console.log(response);
        dispatchGlobal({
          type: 'ADD_STATE',
          key: 'validationResults',
          value: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return {
    initStates,
  };
};

export default useInitStates;
