import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import languageTabs from '../../../../locate/LanguageTabs';
import {
  AssetFolderResponse,
  AssetResponse,
  BrandResponse,
  CreateBrandRequest,
  TranslatedTypeResponseOfAssetResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import Dropzone from '../../../elements/dropzone/Dropzone';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import { Check, TabSelector } from '../../../elements/selectors/Selectors';
import {
  hasError,
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './brandprofile.css';
import Popup from '../../../elements/popup/Popup';
import FileBrowser from '../../../elements/filebrowser/FileBrowser';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Card, { CardSection } from '../../../elements/card/Card';
import FormBuilder from '../../../elements/formbuilder/FormBuilder';

interface BrandProfileProps {
  brand: BrandResponse | CreateBrandRequest;
  updateBrand: (brand: BrandResponse | CreateBrandRequest) => void;
  deleteLogo: (lang: TranslatedStringIndex) => void;
  addLogo: (lang: TranslatedStringIndex, asset: AssetResponse) => void;
  logoAssets?: TranslatedTypeResponseOfAssetResponse | null;
  errors?: Error[];
}

const BrandProfile: React.FC<BrandProfileProps> = ({
  brand,
  logoAssets,
  updateBrand,
  addLogo,
  deleteLogo,
  errors,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.brand.profile',
  });
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const errorHandler = useErrorHandler();
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);

  const [fileBrowserPopup, setFileBrowserPopup] = useState(false);
  const [isDeliveryPaused, setIsDeliveryPaused] = useState(
    !!(brand.deliveryPausedFrom && brand.deliveryPausedUntil)
  );

  useEffect(() => {
    getAssetFolders();
  }, []);

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return (
    <Card bigScreenWidth={'full'}>
      <CardSection>
        <FormBuilder
          contentObject={brand}
          setContentObject={updateBrand}
          elements={[
            {
              type: 'input',
              title: t('slug'),
              hint: t('slug_hint'),
              objectPath: 'slug',
              errors: hasError(errors, ['Slug']),
            },
            {
              type: 'toggle',
              objectPath: 'isActiveInStorefront',
              label: t('isActiveInStorefront'),
            },
            {
              type: 'group',
              children: [
                {
                  type: 'input',
                  title: t('earliestDeliveryTimeInDays'),
                  objectPath: 'earliestDeliveryTimeInDays',
                  errors: hasError(errors, ['EarliestDeliveryTimeInDays']),
                  cleanupFunc: 'emptyStringToNull',
                },
                {
                  type: 'input',
                  title: t('latestDeliveryTimeInDays'),
                  objectPath: 'latestDeliveryTimeInDays',
                  errors: hasError(errors, ['LatestDeliveryTimeInDays']),
                  cleanupFunc: 'emptyStringToNull',
                },
              ],
            },
            {
              type: 'toggle',
              title: t('overrideProductDeliveryTime'),
              objectPath: 'overrideProductDeliveryTime',
              hint: t('overrideProductDeliveryTime_hint'),
              disabled: !(
                !!brand.earliestDeliveryTimeInDays &&
                !!brand.latestDeliveryTimeInDays
              ),
            },
          ]}
        />
      </CardSection>
      <CardSection>
        <Check
          text={t('isDeliveryPaused')}
          checked={isDeliveryPaused}
          update={() => {
            setIsDeliveryPaused(!isDeliveryPaused);
            if (isDeliveryPaused) {
              updateBrand({
                ...brand,
                deliveryPausedFrom: null,
                deliveryPausedUntil: null,
              });
            } else {
              updateBrand({
                ...brand,
                deliveryPausedFrom: new Date().toISOString(),
                deliveryPausedUntil: new Date().toISOString(),
              });
            }
          }}
        />
        {isDeliveryPaused ? (
          <FormBuilder
            contentObject={brand}
            setContentObject={updateBrand}
            elements={[
              {
                type: 'group',
                children: [
                  {
                    type: 'date',
                    title: t('deliveryPausedFrom'),
                    objectPath: 'deliveryPausedFrom',
                    dateFormat: 'dd/MM/yyyy',
                    returnDateFormat: 'ISO',
                  },
                  {
                    type: 'date',
                    title: t('deliveryPausedUntil'),
                    objectPath: 'deliveryPausedUntil',
                    dateFormat: 'dd/MM/yyyy',
                    returnDateFormat: 'ISO',
                  },
                ],
              },
            ]}
            helperCSSClass={'brandprofile-deliveryPaused'}
          />
        ) : null}
      </CardSection>
      <CardSection>
        <div className="brandprofile">
          <TabSelector
            tabs={languageTabs}
            activeTabKey={selectedLang}
            update={(key) => setSelectedLang(key)}
            look="spanToEdges"
          />
          <div className="brandprofile-content">
            <div className="brandprofile-meta">
              <FormBuilder
                key={selectedLang}
                contentObject={brand}
                setContentObject={updateBrand}
                elements={[
                  {
                    type: 'input',
                    title: t('name'),
                    objectPath: `name[${selectedLang}]`,
                    errors: hasError(errors, ['Name']),
                  },
                  {
                    type: 'input',
                    title: t('claim'),
                    objectPath: `claim[${selectedLang}]`,
                    errors: hasError(errors, ['Claim']),
                  },
                ]}
              />
            </div>
            <div className="brandprofile-logo">
              <ErrorCheck
                errors={errors}
                checkFor={['$.logoId.' + selectedLang]}
              />
              {logoAssets &&
              logoAssets[selectedLang as TranslatedStringIndex] ? (
                <>
                  <img
                    src={logoAssets[selectedLang as TranslatedStringIndex]?.uri}
                    className="brandprofile-logo-img"
                    alt="logo"
                  />
                  <div className="brandprofile-logo-actions">
                    <Button
                      cta={t('deleteLogo')}
                      look="secondary-danger"
                      width="full"
                      action={() =>
                        deleteLogo(selectedLang as TranslatedStringIndex)
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="brandprofile-logo-dropzone">
                    <Dropzone
                      maxFiles={1}
                      callback={(responses) =>
                        addLogo(
                          selectedLang as TranslatedStringIndex,
                          responses[0]
                        )
                      }
                    />
                  </div>
                  <div className="brandprofile-logo-actions">
                    <Button
                      cta={t('chooseFromGallery')}
                      look={'secondary'}
                      width="full"
                      action={() => setFileBrowserPopup(true)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <Popup
            width={'50%'}
            toggled={fileBrowserPopup}
            close={() => setFileBrowserPopup(false)}
          >
            {assetFolders ? (
              <FileBrowser
                assetFolders={assetFolders}
                allowUrlNavigation={false}
                onChooseAssets={(assets) => {
                  addLogo(selectedLang as TranslatedStringIndex, assets[0]);
                  setFileBrowserPopup(false);
                }}
              />
            ) : (
              <LoadingContainer />
            )}
          </Popup>
        </div>
      </CardSection>
    </Card>
  );
};

export default BrandProfile;
